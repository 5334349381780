import { addSpaceToTitle } from '../../../../utils/constant'
import { CiEdit } from 'react-icons/ci'

function DetailsItem({cardDataState, displayDeciders, handleOpenEditModal, itemInfo, nested}:any) {

    const readonlyProps = ['cacnatureofbusinessid', 'cacspecificnatureofbusinessid']
    
    const requiredProps = ['cacnatureofbusiness', 'cacspecificnatureofbusiness', 'cacnatureofbusinessid', 'cacspecificnatureofbusinessid', 'cacdescription', 'streetnumber', 'streetaddress', 'district', 'state', 'gender']

    return (
        <>
            {
                itemInfo && !nested &&
                Object.entries(cardDataState)?.map(([key, value], index) => (
                    !displayDeciders['omitFromDisplay'].includes(key)
                    ?   (value !== '' && value !== null) &&
                        <div key={key}>
                            <span onClick={() => handleOpenEditModal( itemInfo && itemInfo['title'], {[key]: value})} className='cursor-pointer text-sm text-gray flex gap-2 items-center'>
                                { addSpaceToTitle(key) }
                                { itemInfo && itemInfo['edit'] && <CiEdit size={15}/> }
                            </span>
                            <p className='break-all'>{ value!?.toString() }</p>
                        </div>
                    :   ''
                ))
            }
            {
                itemInfo && nested &&
                Object.entries(cardDataState)?.map(([outerObject, outerValue], index) => (
                    !displayDeciders['omitFromDisplay'].includes(outerObject) 
                    ?   ((outerValue !== '' && outerValue !== null) || requiredProps.includes(outerObject?.toLowerCase()!)) &&
                            <div key={outerObject}>
                                <div className='text-sm text-gray flex gap-2 items-center'>
                                    { addSpaceToTitle(outerObject) }
                                    {
                                        !readonlyProps.includes(outerObject.toLowerCase()) &&
                                        <span onClick={() => handleOpenEditModal(itemInfo && itemInfo['title'], {[outerObject]: outerValue})} className='cursor-pointer'>
                                            { itemInfo && itemInfo['edit'] && <CiEdit size={15}/> }
                                        </span>
                                    }
                                </div>
                                <div>
                                    <p className='break-all'>{ outerValue!?.toString() }</p>
                                    { (outerValue === '' || outerValue === null) && <p className='break-all text-amber font-semibold'>Value is null, enter appropriate info </p> }
                                </div>
                            </div>
                    :   displayDeciders['nestedInformation'].includes(outerObject) && outerValue &&
                            Object.entries(outerValue)?.map(([innerObject, InnerValue], index) => (
                                ((InnerValue !== '' && InnerValue !== null) || requiredProps.includes(innerObject?.toLowerCase())) &&
                                <div key={innerObject}>
                                    <div className='text-sm text-gray flex gap-2 items-center'>
                                        {outerObject} { addSpaceToTitle(innerObject).toLowerCase() === 'district' ? ' LGA' : addSpaceToTitle(innerObject) }
                                        {
                                            !readonlyProps.includes(innerObject.toLowerCase()) &&
                                            <span onClick={() => handleOpenEditModal(itemInfo && itemInfo['title'], {[innerObject]: InnerValue}, outerObject)} className='cursor-pointer text-sm text-gray flex gap-2 items-center'>
                                                {itemInfo && itemInfo['edit'] && <CiEdit size={15}/> }
                                            </span>
                                        }
                                    </div>
                                    <div>
                                        <p className='break-all'>{ InnerValue ? InnerValue?.toString() : '' }</p>
                                        { (InnerValue === '' || InnerValue === null) && <p className='break-all text-amber font-semibold'>Value is null, enter appropriate info </p> }
                                    </div>
                                </div>
                            ))
                ))
            }
        </>
    )
}

export default DetailsItem