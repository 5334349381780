import { useQuery } from "@tanstack/react-query";
import { fetchApplicationStatuses, fetchApplicationTypes, fetchCountries, fetchCurrentUserProfile, fetchGroups, fetchLineOfBusiness, fetchPartnerCompanies, fetchPartnerTypes, fetchQueryTypes, fetchSlaTypes, fetchTINOrganisationTypes, fetchTINStates } from "../api/Info";
import { useEffect, useState } from "react";
import { GroupType, OptionsType } from "../../utils/typings/Shared";
import { fetchPartnerDetails, fetchPartners } from "../api/Partners";
import { fetchPricingByCountry } from "../api/Settings";
import { fetchNatureOfBusiness } from "../api/Applications";
import { PartnerColumnInterface } from "../../utils/typings/Partners";


export const useApplicationStatusData = ({ enable }: { enable: boolean}) => {

    const [statuses, setstatuses] = useState([])
    
    const { data, isLoading, isError } = useQuery({
        queryKey: ['application-statuses'],
        queryFn: () => fetchApplicationStatuses(),
        enabled: !!enable ?? true
    })

    useEffect(() => {
        if(data){
            setstatuses(data?.map((name:string) => ({value: name, label: name})))
        }
    }, [data])

    return {data, statuses, isLoading, isError}
}

export const useSlaData = () => {

    const [slaData, setslaData] = useState([])
    
    const { data, isLoading, isError } = useQuery({
        queryKey: ['sla-types'],
        queryFn: () => fetchSlaTypes()
    })

    useEffect(() => {
        if(data){
            setslaData(data?.map((name:string) => ({value: name, label: name})))
        }
    }, [data])

    return {data, slaData, isLoading, isError}
}

export const useApplicationTypesData = ({ enable }: { enable: boolean}) => {

    const [services, setservices] = useState<OptionsType>([])
    
    const { data, isLoading, isError } = useQuery({
        queryKey: ['application-types'],
        queryFn: () => fetchApplicationTypes(),
        enabled: !!enable ?? true
    })

    useEffect(() => {
        if(data){
            setservices(data?.map((name:string) => ({value: name, label: name})))
        }
    }, [data])

    return {data, services, isLoading, isError}
}

export const usePartnerTypesData = () => {

    const [userRoles, setuserRoles] = useState<OptionsType>([])
    
    const { data, isLoading, isError } = useQuery({
        queryKey: ['partner-types'],
        queryFn: () => fetchPartnerTypes()
    })

    useEffect(() => {
        if(data){
            setuserRoles(data?.map((name:string) => ({value: name, label: name})))
        }
    }, [data])

    return {data, userRoles, isLoading, isError}
}

export const useGroupsData = () => {

    const [groupType, setgroupType] = useState<OptionsType>([])
    
    const { data } = useQuery({
        queryKey: ['groups'],
        queryFn: () => fetchGroups()
    })

    useEffect(() => {
        if(data){
            setgroupType(data?.map((name:GroupType) => ({value: name.id, label: name.name})))
        }
    }, [data])

    return {data, groupType}
}

export const useCountriesData = () => {
    
    const { data:countries } = useQuery({
        queryKey: ['countries'],
        queryFn: () => fetchCountries()
    })

    return {countries}
}

export const usePricingByCountriesData = (country:string) => {
    
    const { data:pricing, isLoading } = useQuery({
        queryKey: ['pricing-by-country', country],
        queryFn: () => fetchPricingByCountry(country),
        enabled: !!country
    })

    return { pricing, isLoading }
}

export const usePartnerDetailsData = (email: string) => {
    
    const { data } = useQuery({
        queryKey: ['partner-details', email],
        queryFn: () => fetchPartnerDetails(email!),
        enabled: !!email!
    })

    return { data }
}

export const useCurrentUserData = () => {
    
    const { data } = useQuery({
        queryKey: ['currentUserProfile'],
        queryFn: () => fetchCurrentUserProfile(),
    })

    return { data }
}

export const usePartnerData = (type: string) => {
    
    const [partnersList, setPartnersList] = useState()
    
    const { data: verifiedPartners, isFetching, isError } = useQuery({
        queryKey: ['verified-partners', type],
        queryFn: () => fetchPartners({ status: true, user: type }),
        enabled: type !== 'engineering',
    })

    useEffect(() => {
        if(verifiedPartners){
            setPartnersList(verifiedPartners?.map((name:PartnerColumnInterface) => ({value: name.emailAddress, label: name.userProfile.displayName})))
        }
    }, [verifiedPartners])

    return { partnersList, verifiedPartners, isFetching, isError }
}

export const usePartnerCompaniesData = () => {
    const [partnerCompanies, setPartnerCompanies] = useState([
        {
            value: 'Pages By Dami',
            label: 'Pages By Dami',
        }
    ])
    
    const { data } = useQuery({
        queryKey: ['partner-companies'],
        queryFn: () => fetchPartnerCompanies()
    })

    useEffect(() => {
        if(data){
            const companies = data?.map((name:string[]) => ({value: name, label: name}))
            setPartnerCompanies([
                ...partnerCompanies,
                ...companies
            ])
        }
    }, [data])

    return { partnerCompanies }
}

export const useNatureOfBusinessData = (applicationType:string) => {
    const { data, isLoading } = useQuery({
        queryKey: ['nature-of-business'],
        queryFn: () => fetchNatureOfBusiness(applicationType),
        enabled: !!applicationType
    })

    return { data, isLoading }
}

export const useTINOrganisationTypes = () => {

    const [organisationTypes, setOrganisationTypes] = useState<OptionsType>([])

    const { data, isLoading } = useQuery({
        queryKey: ['organisation-types'],
        queryFn: () => fetchTINOrganisationTypes()
    })

    useEffect(() => {
        if(data){
            setOrganisationTypes(data?.map((name:GroupType) => ({value: name, label: name})))
        }
    }, [data])

    return { organisationTypes, isLoading }
}

export const useLineOfBusinessData = () => {

    const [lineOfBusinessData, setLineOfBusinessData] = useState<{ code: string, value: string, label: string}[]>([])

    const { data, isLoading } = useQuery({
        queryKey: ['line-of-business'],
        queryFn: () => fetchLineOfBusiness()
    })

    useEffect(() => {
        if(data){
            setLineOfBusinessData(data?.map((name:{ firsSpecificNatureOfBusiness: string, firsSpecificNatureOfBusinessId: string}) => ({
                code: name.firsSpecificNatureOfBusinessId, 
                value: name.firsSpecificNatureOfBusiness, 
                label: name.firsSpecificNatureOfBusiness
            })))
        }
    }, [data])

    return { lineOfBusinessData, isLoading }
}

export const useTINStatesData = () => {

    const [tinStatesData, setTinStatesData] = useState<{ code: string, value: string, label: string}[]>([])

    const { data, isLoading } = useQuery({
        queryKey: ['tin-states'],
        queryFn: () => fetchTINStates()
    })

    useEffect(() => {
        if(data){
            setTinStatesData(data?.map((value:{ state: string}) => ({
                value: value.state, 
                label: value.state
            })))
        }
    }, [data])

    return { data, tinStatesData, isLoading }
}

export const useQueryTypesData = () => {

    const [allQueryTypes, setAllQueryTypes] = useState<{ code: string, value: string, label: string}[]>([])
    const [queryTypesWithExclusion, setQueryTypesWithExclusion] = useState<{ code: string, value: string, label: string}[]>([])
    const [queryStructure, ] = useState<{ title: string, hasExtraRequirement: boolean} | any>({})
    const { data, isLoading } = useQuery({
        queryKey: ['query-types'],
        queryFn: () => fetchQueryTypes()
    })

    const hasExtraRequirement = ['ChangeOfAddress', 'ChangeOfNatureOfBusiness', 'ChangeOfProprietorDetails', 'ChangeOfID', 'ChangeOfSignature', 'ChangeOfPassport', 'MissingProficiencyCertificate']
    const excludedQueryTypes = ['Others', 'NotSpecified', 'NameSubstitution', 'MissingReservationPayment', 'MissingFilingPayment', 'CommencementDate'];
    useEffect(() => {
        if(data){
            const mappedData = data?.map((value: string) => ({
                value: value, 
                label: value,
            }))

            const filteredData = mappedData.filter((item:{ value: string }) => !excludedQueryTypes.includes(item.value))

            mappedData.forEach((value: { label: string; }) => {
                hasExtraRequirement.includes(value.label) 
                ?   queryStructure[value.label as keyof typeof queryStructure] = {
                        title: value.label,
                        hasExtraRequirement: true
                    }
                :   queryStructure[value.label as keyof typeof queryStructure] = {
                        title: value.label,
                    }
            })
            setAllQueryTypes(mappedData)
            setQueryTypesWithExclusion(filteredData)
        }
    }, [data])

    return { data, allQueryTypes, queryStructure, isLoading, queryTypesWithExclusion }
}